import React, { Fragment } from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Card from '../components/Card'
import TopImage from '../components/TopImage'

import newsImg from '../images/news.jpg'

const news = [
  {
    id: 1,
    preTitolo: 'Luglio 2020',
    nome: 'Con GLS le tue spedizioni arrivano ovunque!',
    descrizione:
      'Con GLS puoi spedire in Europa e nel mondo con un servizio affidabile, di alta qualità e con tempi di consegna brevi!',
    linkUrl: '/news_luglio.pdf',
  },
  {
    id: 2,
    preTitolo: 'Giugno 2020',
    nome: 'GLS per un mondo sostenibile!',
    descrizione: `GLS si prende sempre cura dell'ambiente e delle tue spedizioni.`,
    linkUrl: '/news_giugno.pdf',
  },
  {
    id: 3,
    preTitolo: 'Maggio 2020',
    nome: `Il tuo partner per l'eCommerce`,
    descrizione:
      'Riduci al minimo i tuoi sforzi di gestione e soddisfa al massimo i tuoi clienti!',
    linkUrl: '/news_maggio.pdf',
  },
]

const News = () => {
  return (
    <Layout>
      <SEO
        title="News"
        description="Mediterranea Express - Corriere GLS Sicilia - Le ultime novità"
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          News
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg lg:shadow-xl">
        <TopImage imgUrl={newsImg} />
        <div className="flex flex-wrap py-12 px-4">
          {news.map((n) => {
            return (
              <Fragment key={n.id}>
                <Card oggetto={n} externalLink />
              </Fragment>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default News
